import React from 'react'
import Helmet from 'react-helmet'

import { makeStyles } from '@material-ui/core/styles'
import { Grid, Typography } from '@material-ui/core'

import { FeatureBox } from '../siteblocks'
import DownloadButtonAndroid from '../components/DownloadButtonAndroid'
import DownloadButtonIos from '../components/DownloadButtonIos'
import Hero from '../components/Hero'
import HeroTitle from '../components/HeroTitle'
import Layout from '../components/Layout'
import screenshotMyTasks from '../images/mobile-mytasks.png'
import screenshotTeam from '../images/mobile-team.png'
import screenshotProjects from '../images/mobile-projects.png'

const useStyles = makeStyles(
	theme => ({
		marginBottom: {
			marginBottom: theme.spacing(8),
		},
		imgScreenshot: {
			boxShadow: theme.shadows[20],
			borderRadius: 2,
			display: 'block',
			transition: theme.transitions.create(),
			'&:hover': {
				boxShadow: theme.shadows[24],
				transform: 'scale( 1.01 )',
			},
			[theme.breakpoints.up('lg')]: {
				borderRadius: 8,
			},
			[theme.breakpoints.only('md')]: {
				borderRadius: 6,
			},
			[theme.breakpoints.only('sm')]: {
				borderRadius: 4,
			},
		},
	}),
	{ name: 'MobilePage' }
)

const MobilePage = () => {
	const classes = useStyles()

	const images = [
		{ src: screenshotMyTasks, alt: 'My tasks' },
		{ src: screenshotTeam, alt: 'My team' },
		{ src: screenshotProjects, alt: 'My projects' },
	]

	return (
		<Layout isLandingPage>
			<section>
				<Helmet>
					<title>Tyto for mobile</title>
				</Helmet>
				<Hero>
					<div className={classes.marginBottom}>
						<HeroTitle
							title={'Manage your office from your pocket'}
						/>
						<Typography align={'center'} variant={'h5'}>
							Create tasks, delegate tasks, prioritize work — all
							from your mobile phone.
						</Typography>
					</div>
					<Grid
						container
						alignItems={'center'}
						justify={'center'}
						spacing={2}
					>
						<Grid item className={classes.marginBottom}>
							<Grid container spacing={3} justify={'center'}>
								{images.map((props, index) => (
									<Grid
										justify={'center'}
										item
										container
										xs={12}
										sm={4}
										key={index}
									>
										<img
											src={props.src}
											alt={props.alt}
											className={classes.imgScreenshot}
										/>
									</Grid>
								))}
							</Grid>
						</Grid>

						<Grid item xs={12}>
							<Typography align={'center'} component={'p'}>
								Available for beta download now
							</Typography>
						</Grid>

						<Grid item>
							<Grid container spacing={2}>
								<Grid item>
									<DownloadButtonIos />
								</Grid>
								<Grid item>
									<DownloadButtonAndroid />
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Hero>

				<FeatureBox
					color={'yellow'}
					heading={'Hang out with your team online'}
					body={
						'See who’s online, what they’re busy with, how far they are and what their task list looks like— all from the comfort of your mobile phone. Whether you’re working remotely or in the office, Tyto brings your team closer.'
					}
				/>

				<FeatureBox
					color={'green'}
					heading={'Organize your team from anywhere'}
					body={
						'Create tasks, prioritize tasks lists, review work completed and comment on tasks at a time that’s most convenient to you and without interrupting anyone.'
					}
					reverse
				/>
			</section>
		</Layout>
	)
}

export default MobilePage
