import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import { ThemeProvider, withStyles } from '@material-ui/core/styles'

import { container } from '../sharedStyles'
import { darkTheme } from '../themes'

const styles = theme => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
		marginBottom: -theme.spacing(12),
	},
	background: {
		backgroundColor: theme.palette.background.landingPage,
	},
	container: theme.mixins.gutters({
		...container(theme),
		paddingBottom: theme.spacing(),
		paddingTop: theme.spacing(8),
	}),
	divider: {
		height: theme.spacing(12),
		zIndex: 1,

		'& path': {
			fill: theme.palette.background.landingPage,
			strokeWidth: 0,
			width: '100%',
		},
	},
})

class Hero extends PureComponent {
	static propTypes = {
		classes: PropTypes.object,
		className: PropTypes.any,
		children: PropTypes.any,
	}

	render() {
		const { children, classes, className } = this.props
		return (
			<ThemeProvider theme={darkTheme}>
				<div className={cx(classes.root, className)}>
					<div className={classes.background}>
						<div className={classes.container}>{children}</div>
					</div>
					<svg
						className={classes.divider}
						xmlns={'http://www.w3.org/2000/svg'}
						preserveAspectRatio={'none'}
						viewBox={'0 0 5000 400'}
					>
						<path d={'M0 0 L0 400 L5000 0 Z'} />
					</svg>
				</div>
			</ThemeProvider>
		)
	}
}

export default withStyles(styles)(Hero)
