import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

const styles = theme => ({
	root: {
		fontWeight: 700,
	},
})

class HeroTitle extends PureComponent {
	static propTypes = {
		classes: PropTypes.object,
		title: PropTypes.string,
	}

	render() {
		const { classes, title } = this.props
		return (
			<Typography
				className={classes.root}
				align={'center'}
				gutterBottom
				variant={'h3'}
			>
				{title}
			</Typography>
		)
	}
}

export default withStyles(styles)(HeroTitle)
